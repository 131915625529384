import Link from 'next/link'

import React from 'react'

function TopMenu({ menu }) {
    return (
        <>
            <ul className="flex flex-col lg:flex-row">
                {(menu) ? (
                    menu.menuItems.map((item) => (
                        <li key={item.id} className="lg:py-0 lg:ml-6 lg:px-0 py-2 px-5">
                            <Link href={item.value}>
                                <a className="flex items-center text-brand-primary font-medium hover:underline" >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current text-brand-secondary mr-2 group-hover:mr-2 transition-all lg:hidden" width="6.414" height="10.829" viewBox="0 0 6.414 10.829">
                                            <path id="Chevron_Icon" data-name="Chevron Icon" d="M1180.983,1224l4,4,4-4" transform="translate(-1222.586 1190.397) rotate(-90)" fill="none" stroke="#0fa9a2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    </svg>
                                    {item.name}
                                </a>
                            </Link>
                        </li>
                    ))
                ) : ('')}
            </ul>
        </>
    );
}

export default TopMenu;