import { React, useState, useEffect, useCallback } from 'react'
import HeaderMenuItem from './headermenuitem'
import HeaderMenuMobile from './headermenumobile'

function HeaderMenu({ menu, brandMenu, top_menu_tree, show_mobile_menu, autosearch }) {
    const useMediaQuery = (width) => {
        const [targetReached, setTargetReached] = useState(false);

        const updateTarget = useCallback((e) => {
            if (e.matches) {
                setTargetReached(true);
            } else {
                setTargetReached(false);
            }
        }, []);

        useEffect(() => {
            const media = window.matchMedia(`(max-width: ${width}px)`)
            media.addEventListener('change', e => updateTarget(e))

            // Check on mount (callback is not called until a change occurs)
            if (media.matches) {
                setTargetReached(true)
            }

            return () => media.removeEventListener('change', e => updateTarget(e))
        }, [updateTarget, width])

        return targetReached;
    };
    const is_mobile = useMediaQuery(1023)

    return (
        <>
            {/* // for now, I just use the same component till I get it working to a level where I just need to change the bottom-level-items */}
            {(is_mobile) ? (
                <HeaderMenuMobile menuTree={menu} brandMenuTree={brandMenu} top_menu_tree={top_menu_tree} show_mobile_menu={show_mobile_menu} autosearch={autosearch}></HeaderMenuMobile>
            ) : (
                <HeaderMenuItem menuTree={menu} brandMenuTree={brandMenu} top_menu_tree={top_menu_tree} show_mobile_menu={show_mobile_menu} autosearch={autosearch}></HeaderMenuItem>
            )}
        </>
    );
}

export default HeaderMenu;




