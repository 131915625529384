import Link from 'next/link'
import Image from 'next/image';
import router from 'next/router'

import { AnimatePresence, motion } from 'framer-motion'
import { useSelector } from 'react-redux';
import { React, useState, useEffect, useCallback } from 'react'
import Autosearch from './autosearch'
import TopMenu from './topmenu'

function HeaderMenuItem({ menuTree, brandMenuTree, top_menu_tree, show_mobile_menu, autosearch }) {
    const useMediaQuery = (width) => {
        const [targetReached, setTargetReached] = useState(false);

        const updateTarget = useCallback((e) => {
            if (e.matches) {
                setTargetReached(true);
            } else {
                setTargetReached(false);
            }
        }, []);

        useEffect(() => {
            const media = window.matchMedia(`(max-width: ${width}px)`)
            media.addEventListener('change', e => updateTarget(e))

            // Check on mount (callback is not called until a change occurs)
            if (media.matches) {
                setTargetReached(true)
            }

            return () => media.removeEventListener('change', e => updateTarget(e))
        }, [updateTarget, width])

        return targetReached;
    };
    const is_mobile = useMediaQuery(1023)

    const menu_animations = {
        enter: {
            opacity: "1",
            x: 0,
            y: 0,
            transition: {
                duration: 1,
            },
        },
        hidden: {
            opacity: "0",
            x: 0,
            y: 0,
            transition: {
                duration: 1,
            },
        },
        exit: {
            opacity: "0",
            x: 0,
            y: 0,
            transition: {
                duration: 1,
            },
        }
    }

    const [top_level_menu, set_top_level_menu] = useState(false)
    const [second_level_menu, set_second_level_menu] = useState(false)
    const [brands_menu, set_brands_menu] = useState(false)
    const [merchantMenuTree, set_merchantMenuTree] = useState(false)
    const [merchant_menu, set_merchant_menu] = useState(false)

    const user = useSelector((state) => state.user);
    var token = false
    var merchant = false
    if (user[0] != undefined) {
        token = user[0].token
        if (user[0].roles != undefined && user[0].roles.findIndex((element) => element.name == "merchant") >= 0) {
            merchant = true
        }
    }

    const get_header_menu = (menu) => {
        // function to take all the menus and only extract the header one for rendering here
        var result = menu.filter(obj => {
            return obj.slug == 'header'
        })
        return result[0]
    }

    const get_link = (item) => {
        // we have a few different options in here

        // blog
        // product
        // taxonomy
        // this has different 'types'
        // landingpage
        // static link
        // other built in one

        // but basically there are 2 types:
        // one that has its url as the 'value' and one that has its url as item->value->url


        switch (item.type) {
            case "Blog":
            case "Product":
            case "Taxonomy":
            case "Page":
                return item.value.url
            case "static-url":
            case "text":
                return item.value
        }
    }

    const navigate = (item) => {
        var to = get_link(item)
        // turn the menus off
        set_brands_menu(false)
        set_top_level_menu(false)
        set_merchant_menu(false)
        // navigate
        router.push(to, undefined, { shallow: false })
    }

    const toggle_top_level_menu = (menu_id) => {
        // close brand menu if its open
        set_brands_menu(false)
        set_merchant_menu(false)
        // check if we have an open menu
        if (top_level_menu && top_level_menu.id && top_level_menu.id == menu_id) {
            set_top_level_menu(false)
        } else {

            // otherwise
            // take the id from teh function, check the main menu tree for that item
            var match = menuTree.menuItems.findIndex((element) => element.id == menu_id)

            if (match >= 0) {
                // save its children for use later
                set_top_level_menu(menuTree.menuItems[match])
            }
        }
    }

    const toggle_second_level_menu = (menu_id) => {
        // check if we have an open menu
        if (second_level_menu && second_level_menu.id && second_level_menu.id == menu_id) {
            set_second_level_menu(false)
        } else {

            // otherwise
            // take the id from teh function, check the main menu tree for that item
            var match = top_level_menu.children.findIndex((element) => element.id == menu_id)

            if (match >= 0) {
                // save its children for use later
                set_second_level_menu(top_level_menu.children[match])
            }
        }
    }

    const toggle_brands_menu = (menu_id) => {
        // close other menus if open
        set_top_level_menu(false)
        set_merchant_menu(false)
        // check if we have an open menu
        if (brands_menu !== false) {
            set_brands_menu(false)
        } else {
            // save its children for use later
            set_brands_menu(brandMenuTree)
        }
    }
    const toggle_merchant_menu = (menu_id) => {
        // close other menus if open
        set_top_level_menu(false)
        set_brands_menu(false)
        // check if we have an open menu
        if (merchant_menu !== false) {
            set_merchant_menu(false)
        } else {
            // take the id from teh function, check the main menu tree for that item
            var match = Object.keys(merchantMenuTree.menuItems)
            if (match >= 0) {
                // save its children for use later
                set_merchant_menu(merchantMenuTree.menuItems[match].children)
            }
        }
    }

    const get_column_count = (children) => {
        if (Number(children) <= 5) {
            return " lg:columns-1 "
        }
        if (Number(children) > 5 && Number(children) <= 10) {
            return "lg:columns-2 "
        }
        if (Number(children) > 10) {
            return " lg:columns-3 "
        }
    }


    const get_post_thumbnail = (media) => {
        // check the media for 'thumb' collection
        var match = media.findIndex((element) => element.collection_name == "thumb")
        if (match >= 0) {
            // take the thumbnail and return it
            return media[match].original_url
        } else {
            // if none, then return zeroth media element
            return media[0].original_url
        }
    }


    useEffect(() => {
        if (!router.isReady) return;
        // this one determines what kind of taxonomies we are checking against

        if (merchant) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }

            // fetch serverside while rendering

            fetch(
                process.env.NEXT_PUBLIC_API_URL + '/menu/merchant/header',
                {
                    headers: headers,
                    method: 'POST'
                }
            ).then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json() // or return response.text()
            })
                .catch(async response => {
                    const error = await response.json().then(text => text)
                    return Promise.reject(error)
                })
                .then(data => {
                    // wrap this as an array as it sends an object back from the server
                    var menu = (get_header_menu([data]))
                    set_merchantMenuTree(menu)
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

    }, [token, merchant]);

    return (
        <>
            <nav className={(show_mobile_menu) ? ("flex lg:flex lg:bg-brand-tertiary bg-white relative z-10") : ("hidden lg:flex lg:bg-brand-tertiary bg-white relative z-10")}>

                <div className="container container-2xl mx-auto flex flex-col lg:flex-row lg:text-white sm:text-brand-primary font-medium border-t border-gray-300 lg:border-t-0 pb-6 lg:pb-0">
                    {(menuTree) ? (
                        // build the top level items only
                        menuTree.menuItems.map((menu) => (
                            (menu.children && menu.children.length > 0) ? (
                                <div key={menu.id}>
                                    <div className="bg-white lg:bg-transparent lg:mr-4 border-b border-gray-300 lg:border-b-0 group">
                                        <button 
                                            type="button" 
                                            onClick={() => toggle_top_level_menu(menu.id)}
                                            className={
                                                (top_level_menu && top_level_menu.id == menu.id)
                                                ? 
                                                ("font-medium cursor-pointer transition-all flex lg:text-base text-lg justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5 bg-brand-primary lg:hover:bg-brand-primary lg:active:bg-brand-primary focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary") 
                                                : 
                                                ("font-medium cursor-pointer transition-all flex lg:text-base text-lg justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5 lg:hover:bg-brand-primary lg:active:bg-brand-primary focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary")
                                            }
                                        >
                                            {menu.name}
                                            {(top_level_menu && top_level_menu.id == menu.id) ? (
                                                <i className="fas fa-angle-up text-white cursor-pointer ml-3"></i>
                                            ) : (
                                                <i className="fas fa-angle-down text-aw-dropdown-arrow group-hover:text-white hover:cursor-pointer ml-3"></i>
                                            )}
                                        </button>
                                    </div>
                                    {
                                        (top_level_menu && top_level_menu.id == menu.id) ? (
                                            // as this is now state-aware, we dont load it all in, we only load in the children of the selected item
                                            <AnimatePresence exitBeforeEnter>
                                                <motion.div
                                                    initial="hidden"
                                                    animate="enter" 
                                                    exit="exit"
                                                    variants={menu_animations}
                                                    className="mt-12 lg:absolute left-0 top-0 w-full bg-white menu-dropdown-bg-override lg:shadow-lg group-hover:opacity-100 z-50 dropdown-inner-content"
                                                >
                                                    <div className="container container-2xl mx-auto font-semibold lg:text-lg text-base">
                                                        <div className="flex text-gray-900 w-full relative">
                                                            <ul className="lg:w-1/3 relative border-r bg-gray-50 border-gray-300">
                                                                {/* we need two loops here, this to make the second level items, one to make the third */}
                                                                {top_level_menu.children.map((submenu) => (
                                                                    <li key={submenu.id} className="lg:m-3">
                                                                        <button
                                                                            type="button" 
                                                                            onClick={() => toggle_second_level_menu(submenu.id)}
                                                                            className="text-brand-primary justify-between font-semibold flex w-full items-center relative px-5 py-1.5 lg:p-0 group hover:cursor-pointer hover:text-gray-900">
                                                                            <span>{submenu.name}</span>
                                                                            {(second_level_menu && second_level_menu.id == submenu.id) ? (
                                                                                <i className="fas fa-chevron-right text-gray-300 ml-auto pl-4 text-sm icon lg:group-hover:opacity-100"></i>
                                                                            ) : (
                                                                                <i className="opacity-0 fas fa-chevron-right text-teal-500 ml-auto pl-4 text-sm icon lg:group-hover:opacity-100"></i>
                                                                            )}
                                                                        </button>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            <div className="lg:text-base lg:bg-white lg:w-2/3 top-0 right-0 bottom-0 lg:pl-16 lg:pr-0 lg:pt-16 px-5 pt-1.5 pb-4">
                                                                {/* we need two loops here, one to make the second level items, this to make the third */}

                                                                {(second_level_menu && second_level_menu.children) ? (
                                                                    <div>
                                                                        {(second_level_menu.value.media && second_level_menu.value.media[0] && second_level_menu.value.media[0].original_url) ? (
                                                                            <>
                                                                                <Image
                                                                                    src={second_level_menu.value.media[0].original_url}
                                                                                    alt={second_level_menu.name}
                                                                                    width="180"
                                                                                    height="180"
                                                                                    className="mb-2 lg:inline-block"
                                                                                    placeholder="blur"

                                                                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Image
                                                                                    src={process.env.NEXT_PUBLIC_COMING_SOON_IMAGE}
                                                                                    alt={second_level_menu.name}
                                                                                    height="180"
                                                                                    width="180"
                                                                                    className="mb-2 lg:inline-block"
                                                                                    placeholder="blur"

                                                                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                                                                />
                                                                            </>
                                                                        )}
                                                                        <h3 className="mt-4 mb-4 text-2xl font-bold lg:block">{second_level_menu.name}</h3>
                                                                        <ul className={get_column_count(second_level_menu.children.length) + "lg:mb-4 mb-0"}>
                                                                            {second_level_menu.children.map((bottommenu) => (
                                                                                <li className="lg:mb-4 mb-3" key={bottommenu.id} >
                                                                                    <a
                                                                                        onClick={() => navigate(bottommenu)}
                                                                                        className="flex items-center group text-brand-primary hover:cursor-pointer">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current text-brand-secondary mr-1 group-hover:mr-2 transition-all" width="6.414" height="10.829" viewBox="0 0 6.414 10.829"><path id="Chevron_Icon" data-name="Chevron Icon" d="M1180.983,1224l4,4,4-4" transform="translate(-1222.586 1190.397) rotate(-90)" fill="none" stroke="#0fa9a2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path></svg> 
                                                                                        {bottommenu.name}
                                                                                    </a>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                        <a
                                                                            onClick={() => navigate(second_level_menu)}
                                                                            className="lg:pt-4 w-full border-t border-gray-300 hover:cursor-pointer mt-4 inline-block text-sm text-brand-primary hover:text-gray-900"
                                                                        >
                                                                            View All {second_level_menu.name}
                                                                        </a>
                                                                    </div>
                                                                ) : ('')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bg-white text-center">
                                                        <span
                                                            onClick={() => navigate(top_level_menu)}
                                                            className="border-t border-gray-300 text-brand-primary text-base font-semibold block hover:bg-brand-primary hover:cursor-pointer hover:text-white py-3"
                                                        >
                                                            View All {top_level_menu.name}
                                                        </span>
                                                    </div>
                                                </motion.div>
                                            </AnimatePresence>
                                        ) : ('')
                                    }
                                </div>
                            ) : (
                                <div className="bg-white lg:bg-transparent lg:mr-4 border-b border-gray-300 lg:border-b-0 group" key={menu.id}>
                                    <span
                                        onClick={() => navigate(menu)}
                                        className="cursor-pointer transition-all lg:inline-block flex lg:text-base text-lg justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5 lg:hover:bg-brand-primary lg:active:bg-brand-primary focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary">
                                        {menu.name}
                                    </span>
                                </div>
                            )
                        ))
                    ) : ('')
                    }

                    {
                        // (brandMenuTree && brandMenuTree.menuItems) ? (
                        //     <div key={brandMenuTree.id}>
                        //         <div className="bg-white lg:bg-transparent lg:mr-4 border-b border-gray-300 lg:border-b-0">
                        //             <button 
                        //                 type="button" 
                        //                 onClick={() => toggle_brands_menu(brandMenuTree.id)}
                        //                 className={
                        //                     (brands_menu && brands_menu.id == brandMenuTree.id) 
                        //                     ? 
                        //                     ("font-medium cursor-pointer transition-all flex lg:text-base text-lg justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5 bg-brand-primary lg:hover:bg-brand-primary lg:active:bg-brand-primary focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary") 
                        //                     : 
                        //                     ("font-medium cursor-pointer transition-all flex lg:text-base text-lg justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5 lg:hover:bg-brand-primary lg:active:bg-brand-primary focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary")
                        //                 }
                        //             >
                        //                 Brands
                        //                 {(brands_menu && brands_menu.id == brandMenuTree.id) ? (
                        //                     <i className="fas fa-angle-up text-white cursor-pointer ml-3"></i>
                        //                 ) : (
                        //                     <i className="fas fa-angle-down text-aw-dropdown-arrow group-hover:text-white hover:cursor-pointer ml-3"></i>
                        //                 )}
                        //             </button>
                        //             {
                        //                 (brands_menu && brands_menu.id == brandMenuTree.id) ? (
                        //                     <motion.div
                        //                         initial="hidden"
                        //                         animate="enter" 
                        //                         exit="exit"
                        //                         variants={menu_animations}
                        //                         className="mt-12 lg:absolute left-0 top-0 w-full bg-gray-50 lg:shadow-lg lg:block">
                        //                         <div className="container container-2xl mx-auto lg:pt-8 lg:pb-3 px-5 pt-6 pb-3">
                        //                             <h4 className="h4 text-gray-900 mb-4">Popular Brands</h4>
                        //                             <div className="grid lg:grid-cols-5 grid-cols-2 lg:gap-8 gap-6 lg:mb-12">
                        //                                 {brands_menu.menuItems.map((menu) => (
                        //                                     <div
                        //                                         key={menu.id}
                        //                                         onClick={() => navigate(menu)}
                        //                                         className="cursor-pointer bg-white shadow rounded px-5 py-3 text-center border-2 border-white hover:border-brand-secondary active:border-brand-primary transition-all">
                        //                                         {(menu.value.media && menu.value.media[0] && menu.value.media[0].original_url) ? (
                        //                                             <>
                        //                                                 <Image
                        //                                                     src={menu.value.media[0].original_url}
                        //                                                     alt={menu.name}
                        //                                                     width={process.env.NEXT_PUBLIC_SMALL_IMAGE_WIDTH}
                        //                                                     height={process.env.NEXT_PUBLIC_SMALL_IMAGE_HEIGHT}
                        //                                                     className="bg-white mb-4 inline-block object-contain"
                        //                                                     placeholder="blur"

                        //                                                     blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                        //                                                 />
                        //                                             </>
                        //                                         ) : (
                        //                                             <>
                        //                                                 <Image
                        //                                                     src={process.env.NEXT_PUBLIC_COMING_SOON_IMAGE}
                        //                                                     alt={menu.name}
                        //                                                     height={process.env.NEXT_PUBLIC_SMALL_IMAGE_HEIGHT}
                        //                                                     width={process.env.NEXT_PUBLIC_SMALL_IMAGE_WIDTH}
                        //                                                     className="bg-white mb-4 inline-block object-contain"
                        //                                                     placeholder="blur"

                        //                                                     blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                        //                                                 />
                        //                                             </>
                        //                                         )}
                        //                                         <h6 className="font-medium text-base text-brand-primary">{menu.name}</h6>
                        //                                     </div>
                        //                                 ))
                        //                                 }
                        //                             </div>
                        //                         </div>
                        //                         <div className="text-center">
                        //                             <Link href="/brands">
                        //                                 <a className="border-t border-gray-300 bg-white text-brand-primary text-base font-semibold block hover:bg-brand-primary hover:text-white py-3">View All Brands</a>
                        //                             </Link>
                        //                         </div>
                        //                     </motion.div>
                        //                 ) : ('')
                        //             }
                        //         </div>
                        //     </div>
                        // ) : ('')
                    }
                    {
                        (merchantMenuTree && merchantMenuTree.menuItems) ? (
                            <>
                                {/* // build the top level items only */}
                                {Object.keys(merchantMenuTree.menuItems).map((menu) => (
                                    (merchantMenuTree.menuItems[menu].children && merchantMenuTree.menuItems[menu].children.length > 0) ? (
                                        <div key={merchantMenuTree.menuItems[menu].id}>
                                            <div className="bg-white lg:bg-transparent lg:mr-4 border-b border-gray-300 lg:border-b-0 group">
                                                <button 
                                                    type="button" 
                                                    onClick={() => toggle_merchant_menu(merchantMenuTree.menuItems[menu].id)}
                                                    className={
                                                        (merchant_menu)
                                                        ? 
                                                        ("font-medium cursor-pointer transition-all flex lg:text-base text-lg justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5 bg-brand-primary lg:hover:bg-brand-primary lg:active:bg-brand-primary focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary") 
                                                        : 
                                                        ("font-medium cursor-pointer transition-all flex lg:text-base text-lg justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5 lg:hover:bg-brand-primary lg:active:bg-brand-primary focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary")
                                                    }                                                        
                                                >
                                                    {merchantMenuTree.menuItems[menu].name}
                                                    {(merchant_menu) ? (
                                                        <i className="fas fa-angle-up text-white cursor-pointer ml-3"></i>
                                                    ) : (
                                                        <i className="fas fa-angle-down text-aw-dropdown-arrow group-hover:text-white hover:cursor-pointer ml-3"></i>
                                                    )}
                                                </button>

                                                {
                                                    (merchant_menu) ? (
                                                        <motion.div
                                                            initial="hidden"
                                                            animate="enter" 
                                                            exit="exit"
                                                            variants={menu_animations}
                                                            className="mt-12 lg:absolute left-0 top-0 w-full bg-gray-50 lg:shadow-lg lg:block">
                                                            <div className="container container-2xl mx-auto lg:pt-8 lg:pb-3 px-5 pt-6 pb-3">
                                                                <h4 className="h4 text-gray-900 mb-4">Merchants</h4>
                                                                <div className="grid lg:grid-cols-5 grid-cols-2 lg:gap-8 gap-6 lg:mb-12">
                                                                    {merchant_menu.map((menu) => (
                                                                        <>
                                                                            <div
                                                                                key={menu.id}
                                                                                onClick={() => navigate(menu)}
                                                                                className="cursor-pointer bg-white shadow rounded px-5 py-3 text-center border-2 border-white hover:border-brand-secondary active:border-brand-primary transition-all">
                                                                                {(menu.value.media && menu.value.media[0] && menu.value.media[0].original_url) ? (
                                                                                    <>
                                                                                        <Image
                                                                                            src={get_post_thumbnail(menu.value.media)}
                                                                                            alt={menu.name}
                                                                                            width={process.env.NEXT_PUBLIC_SMALL_IMAGE_WIDTH}
                                                                                            height={process.env.NEXT_PUBLIC_SMALL_IMAGE_HEIGHT}
                                                                                            className="bg-white mb-4 inline-block object-contain"
                                                                                            placeholder="blur"

                                                                                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                                                                        />
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <Image
                                                                                            src={process.env.NEXT_PUBLIC_COMING_SOON_IMAGE}
                                                                                            alt={menu.name}
                                                                                            height={process.env.NEXT_PUBLIC_SMALL_IMAGE_HEIGHT}
                                                                                            width={process.env.NEXT_PUBLIC_SMALL_IMAGE_WIDTH}
                                                                                            className="bg-white mb-4 inline-block object-contain"
                                                                                            placeholder="blur"

                                                                                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                                <h6 className="font-medium text-base text-brand-primary">{menu.name}</h6>
                                                                            </div>
                                                                        </>
                                                                    ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </motion.div>
                                                    ) : ('')
                                                }
                                            </div>
                                        </div>
                                    ) : ('')
                                ))}
                            </>
                        ) : ('')
                    }
                    <div className="lg:justify-self-end bg-white lg:bg-transparent lg:ml-auto border-b border-gray-300 lg:border-b-0 group" key="ContactUs">
                        <Link href="/contact">
                            <a
                                className="cursor-pointer transition-all lg:inline-block flex lg:text-base text-lg justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5 lg:hover:bg-brand-primary lg:active:bg-brand-primary focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary">
                                Contact Us
                            </a>
                        </Link>
                    </div>

                    {(is_mobile) ? (
                        <>
                            {(token) ? (
                                <>
                                    <Link href="/account"><a className="btn btn-md btn-secondary m-5">My Account</a></Link>
                                    <Link href="/logout"><a className="btn btn-md btn-secondary ml-5 mr-5 mb-5">Logout</a></Link>
                                </>
                            ) : (
                                <>
                                    <Link href="/login"><a className=" btn btn-md btn-secondary m-5">Login</a></Link>
                                    <Link href="/login?create=true"><a className="btn btn-md btn-secondary  ml-5 mr-5 mb-5">Create Account</a></Link>
                                </>
                            )}
                            <TopMenu menu={top_menu_tree}></TopMenu>
                        </>
                    ) : ('')}


                </div>
                <Autosearch autosearch={autosearch}></Autosearch>
            </nav>
        </>
    );
}

export default HeaderMenuItem;